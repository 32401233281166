<template lang="pug">
v-dialog(v-model="dialog", width="600")
  v-card(v-if="setting")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Settings
    v-form(@submit.prevent="submit")
      v-card-text
        v-switch(v-model="setting.status", label="Active")
        v-text-field(
          v-model="setting.email",
          label="Contact Email",
          type="email"
        )
        v-textarea(
          v-model="setting.description",
          label="Description",
          rows="10",
          hint="Supports markdown. Use \"##\" for heading, \"*\" for bullet points, etc.",
          persistent-hint
        )
        v-textarea(
          v-model="setting.refund_policy",
          label="Refund Policy",
          rows="5",
          hint="Supports markdown. Use \"##\" for heading, \"*\" for bullet points, etc.",
          persistent-hint
        )
        v-row
          v-col(cols="6")
            v-select(
              v-model="setting.refund_days",
              label="Refund Days",
              :items="day_options"
            )
          v-col(cols="6")
            v-select(
              v-if="setting.refund_days == 0",
              v-model="setting.refund_hours",
              label="Refund Hours",
              :items="hours_options"
            )
      v-card-actions
        v-spacer
        v-btn(@click="dialog = false", color="grey", text) Cancel
        v-btn(type="submit", color="primary", :loading="loading") Save
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      setting: null,
      loading: false,
      day_options: [1, 2, 3, 4, 5, 6, 7, 14, 0],
      hours_options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 0],
    };
  },
  methods: {
    open(data) {
      this.setting = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    validate() {
      if (!this.setting.email) throw new Error("Email is required");
      this.setting.email = this.setting.email.trim().toLowerCase();
      if (!this.validateEmail(this.setting.email))
        throw new Error("Invalid email format");
      if (!this.setting.description) throw new Error("Description is required");
      if (!this.setting.refund_policy)
        throw new Error("Refund Policy is required");
      if (this.setting.refund_days > 0) {
        this.setting.refund_hours = 0;
      }
      if (this.setting.refund_days < 0) {
        throw new Error("Refund Days must be greater than or equal to 0");
      }
      if (this.setting.refund_hours < 0) {
        throw new Error("Refund Hours must be greater than or equal to 0");
      }
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = re.test(String(email).toLowerCase());
      return result;
    },
    async submit() {
      if (!this.setting._id) return;
      this.loading = true;
      try {
        this.validate();
        const criteria = { _id: this.setting._id };
        const action = {
          $set: {
            status: this.setting.status,
            email: this.setting.email,
            description: this.setting.description,
            refund_policy: this.setting.refund_policy,
            refund_days: this.setting.refund_days,
            refund_hours: this.setting.refund_hours,
          },
        };
        const result = await this.$api.b.rsvtsetting.update({
          criteria,
          action,
        });
        this.$emit("update", result);
        this.$toast.success("Saved");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>