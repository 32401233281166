<template lang="pug">
.s-vstack
  .d-flex.flex-row.align-center
    h2 Booked Up
    v-spacer
    IconBtn(
      @click="edit()",
      color="secondary",
      title="Booked Up",
      icon="mdi-plus",
      :outlined="false"
    )
  p Booked Up is a feature that allows you to block off dates and times for your business.
  v-simple-table
    thead
      tr
        th Date
        th Start
        th End
        th
    tbody
      tr(
        v-for="(bookedup, index) in bookedups",
        :key="index",
        @click="edit(bookedup)",
        role="button"
      )
        td {{ bookedup.date }}
        td {{ bookedup.start | minutesToTime }}
        td {{ bookedup.end | minutesToTime }}
        td.text-right
          DeleteOne(:item="bookedup")
  Form(ref="form")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import DeleteOne from "./DeleteOne";
import Form from "./Form";

export default {
  components: { DeleteOne, Form },
  computed: {
    ...mapGetters(["biz", "bookedups"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz?._id) return;
      const criteria = { biz: this.biz._id };
      try {
        const result = await this.$api.rs.bookedUp.list({ criteria });
        this.$store.dispatch("setBookedups", result);
      } catch (e) {
        this.$store.dispatch("setBookedups", []);
        this.$toast.error(e.response?.data || e.message);
      }
    },
    edit(bookedup) {
      this.$refs.form.open(bookedup);
    },
  },
};
</script>