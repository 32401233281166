<template lang="pug">
v-dialog(v-model="dialog", max-width="500px")
  v-card(v-if="item")
    v-card-title
      span {{ item_name }}
      v-spacer
      v-btn(icon, @click="dialog = false")
        v-icon mdi-close
    v-simple-table
      tbody
        tr
          th Name
          td.text-right {{ item.customer.name }}
        tr
          th Phone
          td.text-right {{ item.customer.phone | phone }}
        tr
          th Guests
          td.text-right {{ item.party_size }}
        tr
          th Time
          td.text-right {{ item.time | datetime("smart", timezone) }}
        tr
          th Total
          td.text-right {{ item.payment.total | currency }}
        tr(v-for="charge in item.payment.charges", :key="charge._id")
          th Paid {{ charge.method }}
          td.text-right {{ charge.amount | currency }}
        tr(v-for="refund in item.refunds", :key="refund._id")
          th Refund at {{ refund.time | datetime("smart", timezone) }}
          td.text-right {{ refund.amount | currency }}
    v-card-text
      .link-box(v-if="item.status >= 0")
        v-btn(color="secondary", small, @click="() => $emit('refund', item)") Refund
        v-btn(color="secondary", small, @click="() => $emit('cancel', item)") Cancel
      v-divider.my-2
      .caption Email: {{ item.customer.email }}
      .caption Created at {{ item.created | datetime("smart", timezone) }}
      .caption ID: {{ item._id }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    setting: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      dialog: false,
      item: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    timezone() {
      return this.biz?.address?.timezone;
    },
    item_name() {
      return (
        this.setting?.items?.find((o) => o._id === this.item?.booked_item?.id)
          ?.name || this.item?.booked_item?.id
      );
    },
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
    },
  },
};
</script>