const state = {
  order: null,
  orders: []
}

const getters = {
  order: state => state.order,
  orders: state => state.orders
}

const actions = {
  setOrder: ({ commit }, data) => { commit('setOrder', data) },
  setOrders: ({ commit }, data) => { commit('setOrders', data) },
  updateOrder: ({ commit }, data) => { commit('updateOrder', data) },
  updateManyOrders: ({ commit }, data) => { commit('updateManyOrders', data) }
}

const mutations = {
  setOrder(state, data) {
    state.order = data
  },
  setOrders(state, data) {
    state.orders = data
  },
  updateOrder(state, data) {
    state.order = data
    state.orders = state.orders.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  updateManyOrders(state, list) {
    state.orders = state.orders.map(o => {
      const found = list.find(item => item._id == o._id)
      return found || o
    })
  }
}

export default { state, getters, actions, mutations }