<template lang="pug">
v-card(flat, v-if="setting")
  v-toolbar(dense, flat, :color="toolbarColor", dark)
    .subtitle-2 Booking | {{ status }}
    v-spacer
    v-btn(@click.stop="edit()", icon)
      v-icon(small) mdi-pencil
  v-card-text
    .s-vstack
      div
        .subtitle-2 Booking Site URL
        .d-flex.flex-row.align-center
          a(:href="url", target="_blank") {{ url }}.mr-2
          ClickToCopy(:text="url", :icon="true")
      .subtitle-2 Contact Email: {{ setting.email }}
      div
        .subtitle-2 Description
        v-md-preview.prose(:text="setting.description")
      div
        .subtitle-2 Refund Policy
        v-md-preview.prose(:text="setting.refund_policy")
      .subtitle-2 Refund Timeframe: {{ refund_timeframe }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    setting: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["biz"]),
    status() {
      return this.setting.status ? "Active" : "Inactive";
    },
    toolbarColor() {
      return this.setting.status ? "info" : "error";
    },
    url() {
      return "https://next.selflane.com/rs/" + this.biz.url;
    },
    refund_timeframe() {
      const days = this.setting?.refund_days || 0;
      const hours = this.setting?.refund_hours || 0;
      if (days > 0) return `${days} days`;
      if (hours > 0) return `${hours} hours`;
      return "No Set";
    },
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
  },
};
</script>