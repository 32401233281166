<template lang="pug">
Page(title="Team Management", :items="items", :doc="doc", :loading="loading")
  Integration.my-10
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import Integration from "/libs/components/Biz/7Shifts/Integration";

export default {
  components: { Integration },
  data() {
    return {
      loading: false,
      items: [
        { title: "Team", url: "/hr/staff/home" },
        { title: "Roles", url: "/hr/staff/roles" },
        { title: "Checklist", url: "/hr/staff/checklist" },
      ],
      doc: "https://docs.selflane.com/biz/employee/team.html",
    };
  },
  computed: {
    ...mapGetters(["biz", "bizservice"]),
    has7Shifts() {
      return this.bizservice?.apps?.some((a) => a.name == "7shifts");
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      this.loading = true;
      await this.loadBizService();
      await this.loadPOS();
      await this.loadDepartments();
      this.loading = false;
    },
    async loadBizService() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.service.account.retrieve(params);
      this.$store.dispatch("setBizService", result);
    },
    async loadPOS() {
      if (!this.$auth.check() || !this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      try {
        const data = await this.$api.pos.retrieve(params);
        if (!data) this.create();
        else this.$store.dispatch("setPOS", data);
      } catch (e) {
        this.create();
      }
    },
    async create() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      const data = await this.$api.pos.create(params);
      this.$store.dispatch("setPOS", data);
    },
    async loadDepartments() {
      if (!this.has7Shifts) {
        this.$store.dispatch("seven/setDepartments", []);
        return;
      }
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.seven.department.list(params);
        this.$store.dispatch("seven/setDepartments", result);
      } catch (e) {
        this.$store.dispatch("seven/setDepartments", []);
        // fail silently
      }
    },
  },
};
</script>
