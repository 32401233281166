<template lang="pug">
div
  SevenRoles(v-if="has7Shifts", :biz="biz")
  v-card(flat, v-if="pos")
    v-toolbar(flat, dense)
      .subtitle-2 Roles ({{ items.length }})
      v-spacer
      IconBtn(@click="handleAdd", title="Add", icon="mdi-plus")
    simple-table
      thead
        tr
          th Name
          th.text-right(v-if="has7Shifts") Department
          th.text-right # Employees
          th.text-right(style="width: 110px") Can Order
          th.text-right(style="width: 110px") Can Manage
          th.text-right(style="width: 90px")
      tbody
        Row(
          v-for="(item, index) in items",
          :key="index",
          :item="item",
          :has7Shifts="has7Shifts",
          @edit="handleEdit(item)",
          @remove="handleRemove(item)"
        )
  RemoveRole(ref="removeRole", @removed="load7Shifts")
  Form(
    ref="form",
    :biz="biz",
    :has7Shifts="has7Shifts",
    :bizservice="bizservice"
  )
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";
import RemoveRole from "./RemoveRole";
import Row from "./Row";
import SevenRoles from "./SevenRoles";

export default {
  components: { SevenRoles, RemoveRole, Form, Row },
  props: {
    biz: { type: Object, required: true },
    bizservice: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["pos"]),
    items() {
      return this.pos?.jobs?.toSorted((a, b) => a.name.localeCompare(b.name));
    },
    has7Shifts() {
      const app = this.bizservice?.apps?.find((a) => a.name == "7shifts");
      return app?.status == "established";
    },
  },
  mounted() {
    this.load7Shifts();
  },
  methods: {
    handleRemove(role) {
      this.$refs.removeRole.handleRemove(role);
    },
    handleEdit(role) {
      this.$refs.form.open(role);
    },
    handleAdd() {
      this.$refs.form.open({ name: "", order: false, manage: false });
    },
    async load7Shifts() {
      if (!this.biz) return;
      if (!this.has7Shifts) {
        this.$store.dispatch("seven/setRoles", []);
        return;
      }
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.seven.role.list(params);
        this.$store.dispatch("seven/setRoles", result);
      } catch (e) {
        this.$store.dispatch("seven/setRoles", []);
        // fail silently
      }
    },
  },
};
</script>
