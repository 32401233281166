<template lang="pug">
div
  SevenUsers(
    :biz="biz",
    v-if="has7Shifts",
    ref="sevenUsers",
    @import="handleImport"
  )
  .link-box.link-box-right
    IconBtn(@click="create()", title="Employee", icon="mdi-account-plus")
    Download(:persons="persons", :accessible="showWage")
    Upload
  Table.mt-3.mb-10(
    :showWage="showWage",
    :items="persons",
    :bizs="bizs",
    :has7Shifts="has7Shifts",
    @edit="edit"
  )
  HRSettings.my-3
  Info.my-3
  Form(
    :biz="biz",
    :enterprise="enterprise",
    :bizs="bizs",
    :pos_list="pos_list",
    :showWage="showWage",
    ref="form"
  )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import SevenUsers from "./SevenUsers";
import Table from "./Table";
import Upload from "./Upload";
import Download from "/libs/components/HR/Person/Download";
import Form from "/libs/components/HR/Person/Form";
import Info from "/libs/components/HR/Person/Info";
import HRSettings from "/libs/components/HR/Settings";

export default {
  components: { SevenUsers, Download, Upload, Table, HRSettings, Info, Form },
  data() {
    return {
      enterprise: null,
      bizs: [],
      pos_list: [],
    };
  },
  computed: {
    ...mapGetters(["biz", "useraccess", "corpaccess", "pos", "bizservice"]),
    ...mapGetters("hr", ["persons"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess?.find((o) => o.user == userId && o.biz == bizId);
    },
    showWage() {
      if (this.corpaccess) return true;
      return this.access?.privilege == 1 || this.access?.access_wage;
    },
    has7Shifts() {
      const app = this.bizservice?.apps?.find((a) => a.name == "7shifts");
      return app?.status == "established";
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      await this.wait(200);
      await this.loadEnterprise();
      await this.loadPOSList();
      await this.loadStaff();
      await this.$refs.sevenUsers?.load();
    },
    async loadEnterprise() {
      try {
        if (this.biz.enterprise) {
          this.enterprise = await this.$api.entp.enterprise.get(
            this.biz.enterprise
          );
          this.bizs = await this.$api.biz.list({
            criteria: { enterprise: this.biz.enterprise },
            select: "name short_name address",
          });
        } else {
          this.enterprise = null;
          this.bizs = [this.biz];
        }
      } catch (e) {
        this.enterprise = null;
        this.bizs = [this.biz];
      }
    },
    async loadPOSList() {
      const ids = this.bizs.map((o) => o._id);
      if (!ids.length) return;
      const params = {
        criteria: { biz: { $in: this.bizs.map((o) => o._id) } },
      };
      try {
        this.pos_list = await this.$api.pos.list(params);
      } catch (e) {
        this.pos_list = [];
      }
    },
    async loadStaff() {
      const ids = this.bizs.map((o) => o._id);
      if (!ids.length) return;
      let params = { criteria: { bizs: { $in: ids } } };
      if (this.biz.enterprise) {
        params = { criteria: { enterprise: this.biz.enterprise } };
      }
      const result = await this.$api.hr.person.list(params);
      this.$store.dispatch("hr/setPersons", result);
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    create() {
      this.$refs.form.open();
    },
    handleImport(item) {
      this.$refs.form.open(item, false);
    },
    edit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>
