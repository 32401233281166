<template lang="pug">
v-dialog(width="500", scrollable, v-model="dialog")
  template(v-slot:activator="{ on }")
    IconBtn.mr-3(v-on="on", title="Add", icon="mdi-plus")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Add New Member
    v-card-text
      v-form(@submit.prevent="submit")
        v-row
          v-col(cols="6")
            v-text-field(
              label="First Name",
              v-model="firstName",
              :error-messages="firstNameErrors",
              @blur="$v.firstName.$touch()"
            )
          v-col(cols="6")
            v-text-field(
              label="Last Name",
              v-model="lastName",
              :error-messages="lastNameErrors",
              @blur="$v.lastName.$touch()"
            )
        v-row
          v-col(cols="6")
            PhoneField(
              v-model="phone",
              :error-messages="phoneErrors",
              @blur="$v.phone.$touch()"
            )
          v-col(cols="6")
            v-text-field(
              label="Email",
              v-model="email",
              :error-messages="emailErrors",
              @blur="$v.email.$touch()"
            )
        BirthdayPicker(v-model="birthday")
        BirthdayPicker(v-model="anniv", label="Anniversary")
        v-btn(
          block,
          color="secondary",
          type="submit",
          :loading="loading",
          :disabled="$v.$invalid"
        ) Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    firstName: { required },
    lastName: { required },
    phone: { required, minLength: minLength(10) },
    email: { email },
  },
  data() {
    return {
      dialog: false,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      birthday: { mm: 0, dd: 0 },
      anniv: { mm: 0, dd: 0 },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push("First Name is required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Last Name is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.minLength &&
        errors.push("Phone should be 10 digits US number");
      !this.$v.phone.required && errors.push("Phone is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      return errors;
    },
  },
  methods: {
    ...mapActions(["addMember"]),
    async submit() {
      if (!this.biz) return;
      if (!this.phone || this.phone.length < 10) {
        this.$toast.error("Phone should be 10 digits US number");
        return;
      }
      this.loading = true;
      const data = {
        bizId: this.biz._id,
        phone: this.phone,
        email: this.email,
        name: { first: this.firstName, last: this.lastName },
        bd: this.birthday,
        anniv: this.anniv,
      };
      try {
        const result = await this.$api.member.create(data);
        this.addMember(result);
        this.reset();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    reset() {
      this.firstName = "";
      this.lastName = "";
      this.phone = "";
      this.email = "";
      this.birthday = { mm: 0, dd: 0 };
      this.anniv = { mm: 0, dd: 0 };
      this.$v.$reset();
    },
  },
};
</script>
