<template lang="pug">
.stats-container.my-5(v-if="biz")
  Cell(icon="mdi-heart", title="Favorites", :value="biz.stats?.fav")
  v-divider(vertical)
  Cell(icon="mdi-star", title="Reviews", :value="biz.stats?.review")
  v-divider(vertical)
  Cell(
    icon="mdi-chart-line",
    title="Weekly Impression",
    :value="impression?.count_recent",
    :diff="impressionChange"
  )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  data() {
    return { impression: null };
  },
  computed: {
    ...mapGetters(["biz"]),
    impressionChange() {
      if (!this.impression) return 0;
      return this.impression.count_recent - this.impression.count_previous;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      this.impression = await this.$api.biz
        .custom("impression/recent_week", params)
        .catch(() => null);
    },
  },
};
</script>

<style lang="sass" scoped>
.stats-container
  display: flex
  align-items: center
  background: white
  border-radius: 0.5rem
</style>