<template lang="pug">
div
  v-menu(
    v-model="dialog",
    width="400",
    :close-on-content-click="false",
    v-if="$auth.check()"
  )
    template(v-slot:activator="{ on }")
      v-btn.text-truncate(text, v-on="on", @click="open")
        v-icon(small, left) {{ icon }}
        .text-notransform {{ title }}
    v-card(width="400", :loading="loading")
      .d-flex.flex-row.justify-center.pa-3
        v-btn-toggle(v-model="mode", color="secondary", dense)
          v-btn(
            v-for="(item, index) in items",
            :key="index",
            :value="item.value"
          )
            span.text-notransform {{ item.text }}
      v-divider
      Partner(v-if="mode == 'partner'", @selected="dialog = false")
      BizPicker(v-else, :bizs="bizAccounts", @selected="dialog = false")
  LoadEnterprise(ref="loadEnterprise")
  LoadBiz(ref="loadBiz")
</template>

<script>
import { mapGetters } from "vuex";
import Partner from "./Partner";
import LoadEnterprise from "./LoadEnterprise";
import LoadBiz from "./LoadBiz";

export default {
  components: { Partner, LoadEnterprise, LoadBiz },
  data() {
    return { dialog: false, mode: "", loading: false };
  },
  computed: {
    ...mapGetters([
      "biz",
      "bizAccounts",
      "navState",
      "salesPerson",
      "salesPersons",
    ]),
    ...mapGetters("entp", ["enterprise", "enterprises"]),
    // Return either the biz name or the sales person name
    title() {
      if (this.navState == "partner")
        return this.salesPerson?.name || "Partner";
      else if (this.navState == "enterprise")
        return this.enterprise?.name || "Enterprise";
      if (this.biz) {
        let short_name = this.biz.short_name?.trim();
        if (short_name) {
          const first_space = this.biz.name.indexOf(" ");
          return this.biz.name.slice(0, first_space) + " > " + short_name;
        }
        return this.biz.name.slice(0, 25);
      }
      return "Select";
    },
    icon() {
      if (this.navState == "partner") return "mdi-card-account-details-star";
      else if (this.navState == "enterprise") return "mdi-domain";
      return "mdi-storefront-outline";
    },
    hasPartner() {
      return this.salesPersons?.length;
    },
    items() {
      let data = [{ text: "Business", value: "biz" }];
      if (this.hasPartner || this.navState == "partner") {
        data.push({ text: "Partner", value: "partner" });
      }
      return data;
    },
  },
  methods: {
    async open() {
      if (this.navState == "partner") this.mode = "partner";
      else this.mode = "biz";

      this.loading = true;
      await this.$refs.loadEnterprise.load();
      await this.$refs.loadBiz.load();
      this.loading = false;
    },
  },
};
</script>
