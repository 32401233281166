<template lang="pug">
.d-flex.flex-row.align-center
  v-menu(offset-y)
    template(v-slot:activator="{ on }")
      v-text-field.flex-box(
        v-on="on",
        :label="date_label",
        v-model="dateText",
        readonly=""
      )
    v-date-picker(v-model="date")
  span.mx-1
  v-menu(:close-on-content-click="false", offset-y)
    template(v-slot:activator="{ on }")
      v-text-field.flex-box-right(
        v-on="on",
        :label="time_label",
        v-model="ampm",
        readonly=""
      )
    v-time-picker(v-model="time")
</template>

<script>
/// pick a datetime
/// input value should be unix timestamp

import moment from "moment-timezone";

export default {
  name: "DatetimePicker",
  props: {
    value: { type: Number, required: true },
    date_label: { type: String, default: "Date" },
    time_label: { type: String, default: "Time" },
  },
  data() {
    return {
      date: moment(this.value).format("YYYY-MM-DD"),
      time: moment(this.value).format("HH:mm"), // 24 hour clock
    };
  },
  computed: {
    dateText() {
      return moment(this.date).format("L");
    },
    ampm() {
      return moment(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm").format(
        "h:mm A"
      );
    },
  },
  watch: {
    value() {
      if (!this.value) return;
      this.date = moment(this.value).format("YYYY-MM-DD");
      this.time = moment(this.value).format("HH:mm");
    },
    date() {
      const datetime = moment(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm");
      this.$emit("input", datetime.valueOf());
    },
    time() {
      const datetime = moment(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm");
      this.$emit("input", datetime.valueOf());
    },
  },
};
</script>

<style lang="sass" scoped>
.flex-box
  flex: 2

  &-right
    flex: 1
</style>
