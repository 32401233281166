<template lang="pug">
Page(title="Profile", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/settings/basic.html",
      items: [
        { title: "Settings", url: "/profile/settings" },
        { title: "Hours", url: "/profile/hours" },
        { title: "Reservation", url: "/profile/reservation" },
        { title: "Delivery", url: "/profile/delivery" },
      ],
    };
  },
};
</script>
