<template lang="pug">
tr(@click="$emit('edit')", role="button")
  th {{ item.name }}
  td.text-right(v-if="has7Shifts") {{ getDepartment(item.department_id) }}
  td.text-right {{ getCount(item) }}
  td.text-right
    v-icon(small, :color="getColor(item.order)") {{ getIcon(item.order) }}
  td.text-right
    v-icon(small, :color="getColor(item.manage)") {{ getIcon(item.manage) }}
  td.text-right
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, v-on="on")
          v-icon mdi-dots-horizontal
      v-list.py-0(dense)
        v-list-item(@click.stop="$emit('remove')")
          v-list-item-content
            v-list-item-title Delete
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
    has7Shifts: { type: Boolean, required: false },
  },
  computed: {
    ...mapGetters("hr", ["persons"]),
    ...mapGetters("seven", ["departments"]),
  },
  methods: {
    getDepartment(id) {
      return this.departments?.find((x) => x.id === id)?.name || "Not Assigned";
    },
    getColor(value) {
      return value ? "success" : "gray";
    },
    getIcon(value) {
      return value ? "mdi-check-circle" : "mdi-minus-circle";
    },
    getCount(role) {
      const count = this.persons
        ?.filter((x) => x.status)
        ?.filter((x) => x.roles?.some((r) => r.role_id === role._id))?.length;
      return count || "-";
    },
  },
};
</script>
