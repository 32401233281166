<template lang="pug">
v-list-item(role="button", v-on="$listeners")
  v-list-item-avatar
    v-avatar(@click.stop="editAvatar")
      v-img(:src="item.photo", v-if="item.photo")
      v-icon(v-else, small) mdi-account
  v-list-item-content
    v-list-item-title
      .d-flex.flex-row.align-center
        span {{ fullname }}
        .caption.text--secondary.ml-2 ({{ item.name }})
    v-list-item-subtitle
      .link-box-tight
        RoleAvatar(v-if="nbizs > 1") {{ nbizs }}
        span {{ roles }}
        .caption.red--text.ml-2(v-if="!item.status") Deactivated
  v-list-item-action
    .link-box-tight
      RoleAvatar(v-if="item.isAdmin") A
      RoleAvatar(color="success", v-if="item.isMaster") F
      v-btn(
        v-if="!synced && has7Shifts",
        v-on="$listeners",
        icon,
        small,
        color="secondary"
      ) Sync
      v-menu(offset-y, v-if="duplicates.length")
        template(v-slot:activator="{ on }")
          v-btn(
            outlined,
            small,
            color="secondary",
            v-on="on",
            :loading="merging"
          ) Merge
        v-list(dense)
          v-list-item(
            v-for="(o, i) in duplicates",
            :key="i",
            @click="merge(o)"
          )
            v-list-item-content
              v-list-item-title {{ o.fullname }}
              v-list-item-subtitle {{ o.roles.map((o) => o.name).join(", ") }}
  ProfilePictureForm(ref="form")
</template>

<script>
import { mapGetters } from "vuex";
import ProfilePictureForm from "./ProfilePictureForm";

export default {
  components: { ProfilePictureForm },
  props: {
    item: { type: Object, required: true },
    has7Shifts: { type: Boolean, default: false },
  },
  data() {
    return {
      merging: false,
    };
  },
  computed: {
    ...mapGetters("hr", ["persons"]),
    fullname() {
      return `${this.item.first_name} ${this.item.last_name}`;
    },
    nbizs() {
      return this.item.bizs?.length || 0;
    },
    // return roles as a comma separated string, sorted and unique
    roles() {
      let data = this.item.roles.map((o) => o.name);
      data = [...new Set(data)];
      return data.sort().join(", ");
    },
    synced() {
      return this.item.external_id;
    },
    duplicates() {
      if (this.item.external_id) return [];
      return this.persons.filter(
        (o) =>
          o._id !== this.item._id &&
          o.first_name === this.item.first_name &&
          o.last_name === this.item.last_name
      );
    },
  },
  methods: {
    editAvatar() {
      this.$refs.form.open(this.item);
    },
    async merge(target) {
      // merge this.item into target
      this.merging = true;
      try {
        const params = {
          sourceId: this.item._id,
          targetId: target._id,
        };
        // result is the merged (target) person
        const result = await this.$api.hr.person.custom("merge", params);
        this.$store.dispatch("hr/updatePerson", result);
        this.$store.dispatch("hr/removePerson", this.item);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.merging = false;
    },
  },
};
</script>
