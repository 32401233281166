import Main from './index.vue'
import Settings from './Settings/index.vue'
import Hours from './Hours/index.vue'
import Reservation from './Reservation/index.vue'
import Delivery from './Delivery/index.vue'

export default [
  {
    path: '/profile', component: Main, meta: { auth: true },
    children: [
      { path: '', redirect: 'settings' },
      { path: 'settings', component: Settings },
      { path: 'hours', component: Hours },
      { path: 'reservation', component: Reservation },
      { path: 'delivery', component: Delivery }
    ]
  }
]