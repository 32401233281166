<template lang="pug">
v-container(fluid)
  Facebook
  Stats
  GroupTiles(:groups="nav_items")
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import NavGroups from "/libs/json/BizNav.json";
import Facebook from "@/components/Settings/Profile/Settings/Facebook";
import Stats from "@/components/Performance/Dashboard/Stats/index";

export default {
  components: { Facebook, Stats },
  metaInfo() {
    const title = process.env.VUE_APP_NAME || "Selflane";
    const description =
      "Navigate to the different sections of your business account.";
    return {
      title,
      meta: [{ name: "description", content: description }],
    };
  },
  computed: {
    ...mapGetters(["biz", "useraccess"]),
    title() {
      return this.app_name + ": Navigation";
    },
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess.find((o) => o.user == userId && o.biz == bizId);
    },
    nav_items() {
      const groups = JSON.parse(JSON.stringify(NavGroups));
      if (!this.access) {
        return [];
      }
      const privilege = this.access.privilege;
      return _.chain(groups)
        .each((group) => {
          group.items = _.filter(group.items, (o) => {
            if (privilege < 3) return o.priv >= privilege;
            else if (privilege == 4) {
              return this.access.items.some((item) => item.name == o.name);
            }
          });
        })
        .filter((group) => {
          if (privilege < 3) return group.priv >= privilege;
          else return group.items?.length;
        })
        .value();
    },
  },
};
</script>
