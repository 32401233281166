<template lang="pug">
div
  LoadRecent.my-10
  List.my-10
  EditBasic
  EditPhone
  EditEmail
</template>

<script>
import EditBasic from "./EditBasic";
import EditEmail from "./EditEmail";
import EditPhone from "./EditPhone";
import List from "./List/index";
import LoadRecent from "./LoadRecent";

export default {
  components: { LoadRecent, List, EditBasic, EditPhone, EditEmail },
};
</script>