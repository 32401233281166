<template lang="pug">
div
  .subtitle-2.info--text Capacity: {{ item.use_table ? "Use Table" : "Use Seats" }}
  div(v-if="item.use_table")
    v-simple-table
      tbody
        tr(v-for="(table, index) in item.tables")
          td # Tables: {{ table.total_table }}
          td.text-right Size: {{ table.min_party }} - {{ table.max_party }}
  div(v-else)
    .subtitle-2 Total Seats: {{ item.total_seats }}
    .subtitle-2 Party Size: {{ item.min_party }} - {{ item.max_party }}
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>