<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="punchcard")
    v-form(@submit.prevent="submit")
      v-toolbar(flat, dense)
        .subtitle-2 {{ title }} Time Card
        v-spacer
        v-switch(
          label="Active",
          v-model="punchcard.status",
          color="secondary",
          hide-details
        )
      v-divider
      v-card-text
        v-row(dense, wrap)
          v-col(cols="6")
            PersonPicker(v-model="serverId", :disabled="!!punchcard._id")
          v-col(cols="4")
            v-select(
              :items="roles",
              item-text="name",
              item-value="role_id",
              v-model="serverRole",
              label="Role"
            )
          v-col(cols="2")
            PriceField(
              v-if="showWage",
              v-model.number="punchcard.rate",
              label="Rate"
            )
          v-col(cols="6")
            .subtitle-2.mb-2 Clock In
          v-col(cols="6")
            .d-flex.flex-row.align-start.justify-space-between
              .subtitle-2.mb-2 Clock Out
              v-switch.my-0(
                v-model="clockedOut",
                hide-details,
                color="secondary",
                dense,
                flat
              )
          v-col(cols="6")
            DateTimePicker(v-model="punchcard.clockIn")
          v-col(cols="6")
            DateTimePicker(v-model="punchcard.clockOut", v-if="clockedOut")
        PriceField(label="Cash Tip", v-model.number="punchcard.cashTip")
        v-btn(block, type="submit", :color="buttonColor", :loading="loading") {{ buttonTitle }}
        .text-center.subtitle-2.my-2(v-if="hours") Total: {{ hours | number(2) }} hrs;
          span.ml-2(v-if="showWage") Pay: {{ (hours * punchcard.rate) | currency }}
        .red--text(v-for="(err, index) in errors", :key="index") {{ err }}
        .caption.text-center
          div(v-if="punchcard.created") Created: {{ punchcard.created | datetime }}
          div(v-if="punchcard._id") ID:
            ClickToCopy.ml-2(:text="punchcard._id")
          .text--red(v-if="punchcard.seven_shifts_message") {{ punchcard.seven_shifts_message }}
</template>
<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import PersonPicker from "/libs/components/HR/Person/Picker";

export default {
  components: { PersonPicker },
  data: () => ({
    dialog: false,
    punchcard: {},
    roles: [],
    loading: false,
    errors: [],
    clockedOut: true,
  }),
  computed: {
    ...mapGetters(["biz", "useraccess"]),
    ...mapGetters("hr", ["persons"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess.find((o) => o.user == userId && o.biz == bizId);
    },
    showWage() {
      return (
        this.access && (this.access.privilege == 1 || this.access.access_wage)
      );
    },
    hours() {
      if (this.punchcard?.clockOut && this.punchcard?.clockIn) {
        return (
          (this.punchcard.clockOut - this.punchcard.clockIn) / 1000 / 60 / 60
        );
      } else return null;
    },
    title() {
      if (this.punchcard?._id) return "Edit";
      return "Create";
    },
    buttonTitle() {
      if (!this.punchcard?.status) return "Inactivate";
      return "Save";
    },
    buttonColor() {
      if (!this.punchcard?.status) return "error";
      return "secondary";
    },
    serverId: {
      get: function () {
        return this.punchcard.server;
      },
      set: function (id) {
        const found = this.persons?.find((o) => o._id === id);
        if (found) {
          this.punchcard.server = id;
          this.punchcard.serverName = found.fullname;
        }
        this.setRoles();
      },
    },
    serverRole: {
      get: function () {
        return this.punchcard.role_id;
      },
      set: function (val) {
        const found = this.roles?.find((o) => o.role_id === val);
        if (found) {
          this.punchcard.role_id = found.role_id;
          this.punchcard.role = found.name;
        }
      },
    },
  },
  mounted() {
    EventBus.$on("edit-punchcard", this.open);
  },
  destroyed() {
    EventBus.$off("edit-punchcard", this.open);
  },
  methods: {
    ...mapActions(["updatePunchcard", "addPunchcard"]),
    async open(data) {
      this.dialog = true;
      this.punchcard = JSON.parse(JSON.stringify(data));
      if (this.punchcard.server) {
        // refresh server
        const person = await this.$api.hr.person.retrieve({
          criteria: { _id: this.punchcard.server },
        });
        this.$store.dispatch("hr/updatePerson", person);
      }
      this.setRoles();
      if (!this.punchcard.clockOut) {
        this.clockedOut = false;
        this.punchcard.clockOut = Date.now();
      } else {
        this.clockedOut = true;
      }
    },
    create() {
      const user = this.$auth.user();
      if (!user) return;
      const data = {
        biz: this.biz._id,
        server: null,
        serverName: null,
        role: null,
        clockIn: Date.now(),
        clockOut: Date.now(),
        rate: 0,
        cashTip: 0,
        editor: user._id,
        editorType: "manager",
        status: true,
      };
      this.open(data);
    },
    setRoles() {
      if (this.punchcard?.server) {
        const found = this.persons.find((o) => o._id === this.punchcard.server);
        if (found) {
          this.roles = found.roles.filter((r) => r.biz === this.biz._id);
          if (!this.punchcard.role && this.roles.length > 0) {
            this.punchcard.role = this.roles[0].name;
            this.punchcard.role_id = this.roles[0].role_id;
          }
          return;
        }
      }
      this.roles = [];
    },
    async submit() {
      this.loading = true;
      const errors = [];
      if (!this.punchcard) {
        errors.push("Invalid punchcard");
        return;
      }
      this.punchcard.biz = this.biz._id;
      if (!this.punchcard.server) errors.push("Select a server");
      if (!this.punchcard.role_id) errors.push("Select a role");
      if (!this.punchcard.clockIn) errors.push("Select clock in time");
      const role = this.roles.find((o) => o.role_id === this.punchcard.role_id);
      if (role) {
        this.punchcard.role = role.name;
      }
      if (
        this.punchcard.clockIn &&
        this.punchcard.clockOut &&
        this.punchcard.clockOut < this.punchcard.clockIn
      )
        errors.push("Clock out should be later than clock in");
      this.errors = errors;
      if (errors.length > 0) {
        this.loading = false;
        return;
      }
      if (!this.clockedOut) this.punchcard.clockOut = 0;

      try {
        if (!this.punchcard._id) {
          const result = await this.$api.punchcard.create(this.punchcard);
          this.addPunchcard(result);
        } else {
          const params = {
            criteria: { _id: this.punchcard._id },
            action: { $set: this.punchcard },
          };
          const result = await this.$api.punchcard.update(params);
          this.updatePunchcard(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
