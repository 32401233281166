<template lang="pug">
v-menu(offset-y, v-model="menu", :close-on-content-click="false")
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", x-small, depressed, v-on="on", :loading="loading") Merge
  v-list
    v-list-item(v-for="item in items", :key="item._id")
      v-list-item-title {{ item.first_name }} {{ item.last_name }}
      v-list-item-action
        v-btn(@click="open(item)", color="secondary", x-small, depressed) Merge
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    biz: { type: Object, required: true },
    user: { type: Object, required: true },
  },
  data() {
    return {
      menu: false,
      loading: false,
      pos_list: [],
    };
  },
  computed: {
    ...mapGetters("hr", ["persons"]),
    // filter persons with no external_id
    items() {
      return this.persons
        ?.filter((r) => !r.external_id)
        ?.toSorted((a, b) => a.first_name.localeCompare(b.first_name));
    },
  },
  methods: {
    async open(item) {
      this.loading = true;
      try {
        await this.getPOSList();
        const roleAssignments = await this.getRoleAssignment();
        const userWage = await this.getUserWage();

        const roles = roleAssignments?.map((o) => {
          // find the job in pos_list
          let job;
          let job_biz;
          for (const pos of this.pos_list) {
            job = pos.jobs.find((j) => j.role_id === o.id);
            job_biz = pos.biz;
            if (job) break;
          }
          if (!job) {
            this.$toast.error(`Job not found for role_id ${o.id}`);
            return;
          }
          const rate =
            userWage?.current_wages?.find((w) => w.role_id === o.role_id)
              ?.wage_cents || 0;
          return {
            role_id: job.role_id,
            biz: job_biz,
            name: o.name,
            rate: rate / 100,
            wage_type: "hourly",
            department_id: o.department_id,
            external_id: o.role_id,
          };
        });
        let person = JSON.parse(JSON.stringify(item));
        person.employeeID = this.user.employee_id || person.employeeID;
        person.first_name = this.user.first_name || person.first_name;
        person.last_name = this.user.last_name || person.last_name;
        person.external_id = this.user.id;
        person.email = this.user.email || person.email;
        person.phone = this.user.mobile_number || person.phone;
        person.location_ids = this.user.location_ids;
        person.department_ids = this.user.department_ids;
        person.dob = this.user.birth_date;
        person.roles = roles;
        person.status = true;
        if (this.user.photo) person.photo = this.user.photo;
        this.$emit("merge", person);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async getRoleAssignment() {
      if (!this.biz) return this.$toast.error("No business selected");
      if (!this.user?.id) return this.$toast.error("No user selected");
      const params = { bizId: this.biz._id, userId: this.user.id };
      const result = await this.$api.seven.roleAssignment.list(params);
      return result;
    },
    async getPOSList() {
      if (this.biz?.enterprise) {
        const enterprise = await this.$api.entp.enterprise.get(
          this.biz.enterprise
        );
        this.pos_list = await this.$api.pos.list({
          criteria: { biz: { $in: enterprise.bizs } },
        });
      } else {
        this.pos_list = await this.$api.pos.list({
          criteria: { biz: this.biz._id },
        });
      }
    },
    async getUserWage() {
      if (!this.biz) return this.$toast.error("No business selected");
      if (!this.user?.id) return this.$toast.error("No user selected");
      const params = { bizId: this.biz._id, userId: this.user.id };
      const result = await this.$api.seven.userWage.list(params);
      return result;
    },
  },
};
</script>