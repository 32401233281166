<template lang="pug">
v-stepper(v-model="step", vertical, flat, tile)
  v-stepper-step(step="1", editable) Start
  v-stepper-content(step="1", :complete="step > 1")
    v-radio-group(v-model="mode")
      v-radio(
        v-for="item in items",
        :key="item.value",
        :label="item.text",
        :value="item.value"
      )
    v-btn(color="secondary", outlined, @click="onNext", :disabled="!mode") Next
  template(v-if="mode == 'no_account'")
    v-stepper-step(step="2") Make Request
    v-stepper-content(step="2")
      Request(@done="$emit('done')", ref="request")
  template(v-else-if="mode == 'has_account'")
    v-stepper-step(step="2") Connect
    v-stepper-content(step="2", :complete="step > 2")
      Connect(@connect="onConnect")
    v-stepper-step(step="3") Select Company
    v-stepper-content(step="3", :complete="step > 3")
      SelectCompany(
        :companies="companies",
        :guid="guid",
        @select="onSelectCompany"
      )
    v-stepper-step(step="4") Select Location
    v-stepper-content(step="4", :complete="step > 4")
      SelectLocation(
        :locations="locations",
        :loading="loading",
        @select="onSelectLocation"
      )
</template>

<script>
import { mapGetters } from "vuex";
import Connect from "./Connect";
import Request from "./Request";
import SelectCompany from "./SelectCompany";
import SelectLocation from "./SelectLocation";

export default {
  components: { Request, Connect, SelectCompany, SelectLocation },
  data() {
    return {
      step: 1,
      mode: "",
      items: [
        { text: "We don't have 7Shifts account yet", value: "no_account" },
        { text: "We have an exisiting account", value: "has_account" },
      ],
      guid: "",
      company: null,
      companies: [],
      locations: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["bizservice", "biz"]),
    app() {
      return this.bizservice?.apps?.find((o) => o.name === "7shifts");
    },
    status() {
      return this.app?.status;
    },
    initiated() {
      return this.status === "initiated";
    },
    established() {
      return this.status === "established";
    },
  },
  methods: {
    open() {
      if (this.initiated || this.established) {
        this.mode = "has_account";
        this.step = 2;
        this.guid = this.app.access_token;
        this.onConnect(this.guid);
      } else {
        this.mode = "";
        this.step = 1;
      }
    },
    async onNext() {
      this.step = 2;
      if (this.mode === "no_account") {
        this.$refs.request.open();
      }
    },
    async onConnect({ guid, companies }) {
      this.guid = guid;
      this.companies = companies;
      this.step++;
    },
    async onSelectCompany({ company_id, locations }) {
      this.company = this.companies.find((o) => o.id === company_id);
      this.locations = locations;
      this.step++;
    },
    async onSelectLocation(location) {
      this.loading = true;
      try {
        const app = {
          name: "7shifts",
          company_id: this.company.id,
          store_id: location.id,
          store_name: location.name,
          access_token: this.guid,
          status: "established",
        };
        const result = await this.$api.service.account.custom("connect", {
          bizId: this.biz._id,
          app,
        });
        this.$store.dispatch("updateBizService", result);
        this.$toast.success("Congratulations! 7Shifts is now connected.");
        this.$emit("done");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>