<template lang="pug">
v-dialog(v-model="dialog", width="600")
  v-card(v-if="setting")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Bookable Item
    v-form(@submit.prevent="submit")
      v-card-text
        .s-vstack
          div
            .subtitle-2.info--text Price and Description
            v-row(wrap, dense)
              v-col(cols="8", md="6")
                v-text-field(v-model="item.name", label="Name", hide-details)
              v-col(cols="4", md="3")
                PriceField(
                  v-model.number="item.price",
                  label="Price/Person",
                  hide-details
                )
              v-col(cols="4", md="3")
                v-select(
                  v-model="item.status",
                  label="Status",
                  :items="status_options",
                  hide-details
                )
              v-col(cols="4", md="3")
                v-switch(
                  v-model="item.add_tax",
                  label="Add Tax",
                  color="secondary"
                )
              v-col(cols="4", md="3")
                v-switch(
                  v-model="item.add_tip",
                  label="Allow Tip",
                  color="secondary"
                )
            v-textarea(
              v-model="item.description",
              label="Description",
              rows="6",
              hint="Supports markdown. Use \"##\" for heading, \"*\" for bullet points, etc.",
              persistent-hint
            )
          div
            .subtitle-2.info--text Capacity
            v-checkbox(v-model="item.use_table", label="Use Table")
            div(v-if="item.use_table")
              p Table Settings
                v-simple-table
                  tbody
                    tr(v-for="(item, index) in item.tables", :key="index")
                      td
                        v-text-field(
                          v-model.number="item.total_table",
                          label="# Tables"
                        )
                      td
                        v-text-field(
                          v-model.number="item.min_party",
                          label="Min Party"
                        )
                      td
                        v-text-field(
                          v-model.number="item.max_party",
                          label="Max Party"
                        )
                v-btn(@click="addTable()", color="secondary") Add Table
            div(v-else)
              v-row(wrap, dense)
                v-col(cols="4")
                  v-text-field(
                    v-model.number="item.total_seats",
                    label="Total Seats"
                  )
                v-col(cols="4")
                  v-text-field(
                    v-model.number="item.min_party",
                    label="Min Party"
                  )
                v-col(cols="4")
                  v-text-field(
                    v-model.number="item.max_party",
                    label="Max Party"
                  )
        div
          .subtitle-2.info--text Availability
          v-switch(
            v-model="edit_time_slots",
            label="Use Custom Time Slots",
            color="secondary"
          )
          v-select(
            v-if="edit_time_slots",
            v-model="item.time_slots",
            label="Time Slots",
            multiple,
            :items="time_slot_options"
          )
          v-row(wrap, dense)
            v-col(cols="6", v-if="!edit_time_slots")
              v-select(
                v-model="item.time_gap",
                label="Time Gap",
                :items="gap_options",
                :hint="gap_hint",
                persistent-hint
              )
            v-col(cols="6", v-if="!edit_time_slots")
              v-select(
                v-model="item.last_booking_time",
                label="Last Booking Time",
                :items="last_booking_time_options",
                :hint="last_hint",
                persistent-hint
              )
            v-col(cols="6")
              .d-flex.flex-row.align-center
                DateFieldPicker(
                  v-model="item.start_date",
                  label="Start Date",
                  hint="Leave empty for no limit",
                  persistent-hint,
                  :min="min_date"
                )
                v-btn(
                  @click="item.start_date = null",
                  icon,
                  v-if="item.start_date",
                  color="error"
                )
                  v-icon(small) mdi-delete
            v-col(cols="6")
              .d-flex.flex-row.align-center
                DateFieldPicker(
                  v-model="item.end_date",
                  label="End Date",
                  hint="Leave empty for no limit",
                  persistent-hint,
                  :min="min_date"
                )
                v-btn(
                  @click="item.end_date = null",
                  icon,
                  v-if="item.end_date",
                  color="error"
                )
                  v-icon(small) mdi-delete
          v-switch(
            v-model="edit_weekdays",
            label="Use Custom Weekday Availability",
            color="secondary"
          )
          div(v-if="edit_weekdays")
            v-select(
              v-model="item.weekdays",
              label="Weekdays",
              multiple,
              :items="weekday_options",
              :hint="weekday_hint",
              persistent-hint
            )
        div
          .subtitle-2.info--text Bookable Window (days)
          v-row(wrap, dense)
            v-col(cols="6")
              v-select(
                v-model="item.days_in_advance_min",
                label="From",
                :items="day_options_mapped"
              )
            v-col(cols="6")
              v-select(
                v-model="item.days_in_advance_max",
                label="To",
                :items="day_options_mapped"
              )
      v-card-actions
        v-spacer
        v-btn(@click="dialog = false", color="grey", text) Cancel
        v-btn(type="submit", color="secondary", :loading="loading") Save
</template>

<script>
import moment from "moment-timezone";
export default {
  data() {
    return {
      dialog: false,
      setting: null,
      item: null,
      loading: false,
      gap_options: [
        { text: "30 minutes", value: 30 },
        { text: "1 hour", value: 60 },
        { text: "1.5 hours", value: 90 },
        { text: "2 hours", value: 120 },
        { text: "2.5 hours", value: 150 },
        { text: "3 hours", value: 180 },
      ],
      gap_hint: "Time gap between slots.",
      last_booking_time_options: [
        { text: "30 minutes", value: 30 },
        { text: "1 hour", value: 60 },
        { text: "1.5 hours", value: 90 },
        { text: "2 hours", value: 120 },
        { text: "2.5 hours", value: 150 },
        { text: "3 hours", value: 180 },
      ],
      last_hint: "Last booking time in minutes before closing.",
      day_options: [1, 2, 3, 4, 5, 6, 7, 14, 21, 28, 0],
      edit_weekdays: false,
      edit_time_slots: false,
      weekday_options: [
        { text: "Sun", value: 0 },
        { text: "Mon", value: 1 },
        { text: "Tue", value: 2 },
        { text: "Wed", value: 3 },
        { text: "Thu", value: 4 },
        { text: "Fri", value: 5 },
        { text: "Sat", value: 6 },
      ],
      weekday_hint: "Select weekdays for availability, leave empty for all.",
      status_options: [
        { text: "Active", value: "active" },
        { text: "Paused", value: "paused" },
      ],
      min_date: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    time_slot_options() {
      const start = moment().startOf("day");
      const end = moment().endOf("day");
      let value = 0;
      const gap = 30;
      let slots = [];
      while (start < end) {
        slots.push({ text: start.format("h:mm A"), value: value });
        start.add(gap, "minutes");
        value += gap;
      }
      return slots;
    },
    day_options_mapped() {
      return this.day_options.map((o) => {
        if (o === 0) return { text: "Same Day", value: o };
        if (o === 1) return { text: "1 day", value: o };
        return { text: o + " days", value: o };
      });
    },
  },
  methods: {
    open(setting, item) {
      this.setting = JSON.parse(JSON.stringify(setting));
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));
        this.item.start_date = this.item.start_date || "";
        this.item.end_date = this.item.end_date || "";
      } else {
        this.item = {
          name: "",
          description: "",
          price: 0,
          use_table: false,
          tables: [],
          min_party: 1,
          max_party: 4,
          total_seats: 10,
          time_slots: [],
          time_gap: 60,
          weekdays: [],
          last_booking_time: 60,
          start_date: "",
          end_date: "",
          days_in_advance_min: 1,
          days_in_advance_max: 14,
          add_tax: false,
          add_tip: false,
          status: "active",
        };
      }
      if (this.item?.weekdays?.length) this.edit_weekdays = true;
      if (this.item?.time_slots?.length) this.edit_time_slots = true;
      this.dialog = true;
    },
    addTable() {
      if (!this.item.tables) {
        this.item.tables = [{ min_party: 1, max_party: 4, total_table: 10 }];
        return;
      }
      this.item.tables.push({ min_party: 1, max_party: 4, total_table: 10 });
    },
    validate() {
      // check price and description
      if (!this.item?.name) throw new Error("Name is required");
      if (!this.item?.description) throw new Error("Description is required");
      if (this.item.price < 0)
        throw new Error("Price must be greater or equal to 0");
      if (!this.item.status) this.item.status = "active";
      // check capacity
      if (this.item.use_table) {
        if (!this.item.tables?.length) {
          throw new Error("Table settings are required");
        }
        const rogueTable = this.item.tables.find(
          (o) =>
            o.min_party <= 0 ||
            o.max_party <= 0 ||
            o.total_table <= 0 ||
            o.max_party < o.min_party
        );
        if (rogueTable) {
          throw new Error("Invalid table settings");
        }
      } else {
        if (!this.item.total_seats) throw new Error("Total Seats is required");
        if (!this.item.min_party) throw new Error("Min Party is required");
        if (!this.item.max_party) throw new Error("Max Party is required");
        if (this.item.total_seats <= 0)
          throw new Error("Total Seats must be greater than 0");
        if (this.item.min_party <= 0)
          throw new Error("Min Party must be greater than 0");
        if (this.item.max_party < this.item.min_party) {
          throw new Error(
            "Max Party must be greater than or equal to Min Party"
          );
        }
      }
      // check availability
      if (!this.edit_weekdays) {
        this.item.weekdays = [];
      } else {
        this.item.weekdays.sort((a, b) => a - b);
        if (!this.item.weekdays.length) {
          throw new Error("Weekdays are required");
        }
      }
      if (!this.edit_time_slots) {
        this.item.time_slots = [];
      } else {
        this.item.time_slots.sort((a, b) => a - b);
        if (!this.item.time_slots.length) {
          throw new Error("Time Slots are required");
        }
      }
      if (this.item.end_date) {
        // ensure end date is greater than today
        if (
          moment(this.item.end_date).startOf("day") < moment().startOf("day")
        ) {
          throw new Error("End Date must be greater than today");
        }
      }
      if (this.item.start_date && this.item.end_date) {
        if (this.item.start_date >= this.item.end_date) {
          throw new Error("Start Date must be before End Date");
        }
      }
      // check booking window
      if (this.item.days_in_advance_min > this.item.days_in_advance_max) {
        throw new Error(
          "Earliest Booking Days must be less than or equal to Latest Booking Days"
        );
      }
    },
    async submit() {
      if (!this.setting) return;
      this.loading = true;
      try {
        this.validate();
        let params;
        if (this.item._id) {
          params = {
            criteria: { _id: this.setting._id, "items._id": this.item._id },
            action: { $set: { "items.$": this.item } },
          };
        } else {
          params = {
            criteria: { _id: this.setting._id },
            action: { $push: { items: this.item } },
          };
        }
        const result = await this.$api.b.rsvtsetting.update(params);
        this.$emit("update", result);
        this.$toast.success("Saved");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>