<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="item")
    v-card-title {{ title }}
    form(@submit.prevent="save")
      v-card-text
        v-row
          v-col(cols="12")
            DateFieldPicker(v-model="item.date")
          v-col(cols="6")
            TimeSelector(v-model="item.start", title="Start")
          v-col(cols="6")
            TimeSelector(v-model="item.end", title="End")
      v-card-actions
        v-btn(type="submit", color="secondary", :loading="loading") Save
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      item: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      return this.item._id ? "Edit Booked Up" : "Add Booked Up";
    },
  },
  methods: {
    open(input) {
      if (input) this.item = JSON.parse(JSON.stringify(input));
      else
        this.item = {
          biz: this.biz._id,
          date: moment().format("YYYY-MM-DD"),
          start: 720,
          end: 900,
        };
      this.dialog = true;
    },
    validate() {
      if (!this.item.date) throw new Error("Date is required");
      if (this.item.start == undefined) {
        throw new Error("Start time is required");
      }
      if (this.item.end == undefined) throw new Error("End time is required");
      if (this.item.start >= this.item.end) {
        throw new Error("Start time must be before end time");
      }
    },
    async save() {
      this.loading = true;
      try {
        this.validate();
        if (this.item._id) {
          const action = {
            $set: {
              date: this.item.date,
              start: this.item.start,
              end: this.item.end,
            },
          };
          const result = await this.$api.rs.bookedUp.put(this.item._id, action);
          this.$store.dispatch("updateBookedup", result);
        } else {
          this.item.biz = this.biz._id;
          const result = await this.$api.rs.bookedUp.create(this.item);
          this.$store.dispatch("addBookedup", result);
        }
        this.dialog = false;
        this.$emit("update");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>