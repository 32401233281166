<template lang="pug">
v-card.mb-3(flat, v-if="items?.length", :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 Not Synced Users ({{ items?.length }})
  simple-table
    thead
      tr
        th Name
        th.text-right Action
    tbody
      tr(v-for="(user, index) in items", :key="index")
        th {{ user.first_name }} {{ user.last_name }}
        td.text-right
          .btn-box
            ImportUser(
              :user="user",
              :biz="biz",
              @import="$emit('import', $event)"
            )
            MergeUser(
              :user="user",
              :biz="biz",
              @merge="$emit('import', $event)"
            )
            DeactivateUser(:user="user", :biz="biz")
</template>

<script>
import { mapGetters } from "vuex";
import DeactivateUser from "./DeactivateUser";
import ImportUser from "./ImportUser";
import MergeUser from "./MergeUser";

export default {
  components: { ImportUser, MergeUser, DeactivateUser },
  props: {
    biz: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["bizservice"]),
    ...mapGetters("seven", ["departments", "roles", "users"]),
    ...mapGetters("hr", ["persons"]),
    app() {
      return this.bizservice?.apps?.find((r) => r.name === "7shifts");
    },
    company_id() {
      return parseInt(this.app?.company_id || 0);
    },
    items() {
      return this.users
        ?.filter((r) => r.company_id === this.company_id)
        ?.filter((r) => !this.persons?.some((s) => s.external_id === r.id))
        ?.filter((r) => r.active)
        .toSorted((a, b) => a.first_name.localeCompare(b.first_name));
    },
  },
  methods: {
    async load() {
      if (!this.biz) return;
      this.loading = true;
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.seven.user.list(params);
        this.$store.dispatch("seven/setUsers", result);
      } catch (e) {
        this.$store.dispatch("seven/setUsers", []);
        // fail silently
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.btn-box
  display: flex
  justify-content: end
  gap: 6px
</style>