<template lang="pug">
InvoiceGuard
  .s-vstack
    StripeAccountGuard
</template>

<script>
import InvoiceGuard from "./InvoiceGuard";
import StripeAccountGuard from "./StripeAccountGuard";

export default {
  name: "AccessGuard",
  components: { InvoiceGuard, StripeAccountGuard },
  methods: {},
};
</script>