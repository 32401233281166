<template lang="pug">
PricingGuard(:min_level="3")
  .s-vstack
    div
      WeekSelector(
        :loading="loading",
        :timezone="timezone",
        :maxDate="maxDate",
        @select="load"
      )
    .s-vstack(v-if="futureGroups.length")
      h2 Current and Future Reservations
      Blocks(
        v-for="group in futureGroups",
        :key="group.date",
        :date="group.date",
        :items="group.items",
        :setting="setting",
        @update="handleUpdate"
      )
    .s-vstack(v-if="pastGroups.length")
      h2 Past Reservations
      Blocks(
        v-for="group in pastGroups",
        :key="group.date",
        :date="group.date",
        :items="group.items",
        :setting="setting",
        @update="handleUpdate"
      )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment";
import _ from "underscore";
import { mapGetters } from "vuex";
import Blocks from "./blocks";

export default {
  components: { Blocks },
  data() {
    return {
      loading: false,
      setting: {},
      items: [],
      maxDate: moment().add(1, "month").format("YYYY-MM-DD"),
      begin: moment().startOf("week").valueOf(),
      end: moment().endOf("week").valueOf(),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    timezone() {
      return this.biz?.address?.timezone || moment.tz.guess();
    },
    pastGroups() {
      const cutoff = moment().startOf("day");
      let filtered = this.items.filter((o) => moment(o.time).isBefore(cutoff));
      return this.getGroups(filtered);
    },
    futureGroups() {
      const cutoff = moment().startOf("day");
      let filtered = this.items.filter((o) => moment(o.time).isAfter(cutoff));
      return this.getGroups(filtered);
    },
  },
  mounted() {
    this.getSetting();
    EventBus.$on("switch-biz", async () => {
      try {
        await this.getSetting();
        await this.load();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    });
  },
  methods: {
    // group by date
    getGroups(items) {
      return _.chain(items)
        .groupBy((o) => moment(o.time).tz(this.timezone).format("YYYY-MM-DD"))
        .map((items, date) => {
          return { date, items: _.sortBy(items, "time") };
        })
        .sortBy("date")
        .value();
    },
    async getSetting() {
      const bizId = this.biz?._id;
      if (!bizId) return;
      const criteria = { _id: bizId };
      this.setting = await this.$api.b.rsvtsetting.retrieve({ criteria });
    },
    async load({ begin, end }) {
      this.begin = begin || this.begin;
      this.end = end || this.end;
      const bizId = this.biz?._id;
      if (!bizId) return;
      const criteria = {
        "seller.id": bizId,
        time: { $gt: this.begin, $lt: this.end },
      };
      this.loading = true;
      try {
        this.items = await this.$api.rs.book.list({ criteria });
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    handleUpdate(item) {
      this.items = this.items.map((o) => (o._id === item._id ? item : o));
    },
  },
};
</script>