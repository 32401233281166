<template lang="pug">
v-btn(@click="submit", icon, small, :loading="loading")
  v-icon(v-if="change == 1", small) mdi-chevron-down
  v-icon(v-else, small) mdi-chevron-up
</template>

<script>
export default {
  props: {
    setting: { type: Object, default: null },
    item: { type: Object, default: null },
    change: { type: Number, default: 1 }, // 1 or -1
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      if (!this.setting?.items?.length || !this.item) return;
      const index = this.setting.items.findIndex(
        (o) => o._id === this.item._id
      );
      if (index < 0) return;
      const newIndex = index + this.change;
      if (newIndex < 0 || newIndex >= this.setting.items.length) return;
      this.loading = true;
      let items = JSON.parse(JSON.stringify(this.setting.items));
      const item = items.splice(index, 1)[0];
      items.splice(newIndex, 0, item);
      try {
        const criteria = { _id: this.setting._id };
        const action = { $set: { items: items } };
        const result = await this.$api.b.rsvtsetting.update({
          criteria,
          action,
        });
        this.$emit("update", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.response);
      }
      this.loading = false;
    },
  },
};
</script>