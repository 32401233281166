<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="role")
    v-card-title {{ title }} a Role
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(v-model="role.name", label="Name", required)
        v-select(
          v-model="role.department_id",
          :items="departments_sorted",
          item-text="name",
          item-value="id",
          label="Department",
          v-if="departments?.length"
        )
        v-row
          v-col(cols="6")
            v-checkbox(
              v-model="role.order",
              label="Can Order",
              color="secondary",
              hide-details
            )
          v-col(cols="6")
            v-checkbox(
              v-model="role.manage",
              label="Can Manage",
              color="secondary",
              hide-details
            )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    biz: { type: Object, required: true },
    bizservice: { type: Object, required: true },
    has7Shifts: { type: Boolean, required: false },
  },
  data() {
    return {
      dialog: false,
      role: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["pos"]),
    ...mapGetters("seven", ["departments"]),
    title() {
      return this.role?._id ? "Edit" : "Add";
    },
    app() {
      return this.bizservice?.apps?.find((r) => r.name == "7shifts");
    },
    company_id() {
      return parseInt(this.app?.company_id || 0);
    },
    departments_sorted() {
      // if default is true, put it at the top
      // otherwise sort by name
      return (
        this.departments
          ?.filter((r) => r.company_id === this.company_id)
          .toSorted((a, b) => {
            if (a.default) return -1;
            if (b.default) return 1;
            return a.name.localeCompare(b.name);
          }) || []
      );
    },
  },
  methods: {
    ...mapActions(["setPOS"]),
    open(role) {
      this.role = JSON.parse(JSON.stringify(role));
      this.dialog = true;
    },
    async submit() {
      if (!this.role) return;
      this.role.name = this.role.name?.trim();
      if (this.has7Shifts) {
        if (!this.role.department_id) {
          this.$toast.error("Department is required");
          return;
        }
      }
      if (!this.role.name) {
        this.$toast.error("Name is required");
        return;
      }
      this.loading = true;
      try {
        const params = { bizId: this.pos.biz, job: this.role };
        if (this.role._id) {
          const result = await this.$api.posJob.update(params);
          this.setPOS(result);
        } else {
          const result = await this.$api.posJob.create(params);
          this.setPOS(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>